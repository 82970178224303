var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Title Color",
              "title-color": "primary",
              subtitle: "Brownie pastry chocolate pastry chocolate pudding.",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("code", [_vm._v("title-color")]),
              _vm._v(
                " prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Title Color"\n    title-color="primary"\n    subtitle="Brownie pastry chocolate pastry chocolate pudding.">\n    <p class="mb-3">You can use <code>title-color</code> prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.</p>\n    <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Title Color",
              "title-color": "success",
              subtitle: "Brownie pastry chocolate pastry chocolate pudding.",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("code", [_vm._v("title-color")]),
              _vm._v(
                " prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Title Color"\n    title-color="success"\n    subtitle="Brownie pastry chocolate pastry chocolate pudding.">\n    <p class="mb-3">You can use <code>title-color</code> prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.</p>\n    <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Subtitle Color",
              "title-color": "primary",
              "subtitle-color": "warning",
              subtitle: "Brownie pastry chocolate pastry chocolate pudding.",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("code", [_vm._v("subtitle-color")]),
              _vm._v(
                " prop to change color of subtitle of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Subtitle Color"\n    title-color="primary"\n    subtitle-color="warning"\n    subtitle="Brownie pastry chocolate pastry chocolate pudding.">\n    <p class="mb-3">You can use <code>subtitle-color</code> prop to change color of subtitle of card. This prop supports hex, rgba, rgb and theme colors.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Content Color",
              "title-color": "primary",
              "content-color": "warning",
              subtitle: "Brownie pastry chocolate pastry chocolate pudding.",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("code", [_vm._v("content-color")]),
              _vm._v(
                " prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Content Color"\n    title-color="primary"\n    content-color="warning"\n    subtitle="Brownie pastry chocolate pastry chocolate pudding."\n    code-toggler>\n    <p class="mb-3">You can use <code>content-color</code> prop to change color of title of card. This prop supports hex, rgba, rgb and theme colors.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Background Color",
              "title-color": "#fff",
              "card-background": "primary",
              "content-color": "#fff",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("strong", [_vm._v("card-background")]),
              _vm._v(
                " prop to change background color of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Background Color"\n    title-color="#fff"\n    card-background="primary"\n    content-color="#fff">\n    <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>\n    <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Background Color",
              "title-color": "#fff",
              "card-background": "success",
              "content-color": "#fff",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("strong", [_vm._v("card-background")]),
              _vm._v(
                " prop to change background color of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Background Color"\n    title-color="#fff"\n    card-background="success"\n    content-color="#fff">\n    <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>\n    <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Gradient Background Color",
              "title-color": "#fff",
              "content-color": "#fff",
              "card-background":
                "linear-gradient(120deg, #7f7fd5, #86a8e7, #91eae4)",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("strong", [_vm._v("card-background")]),
              _vm._v(
                " prop to change background color of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Gradient Background Color"\n    title-color="#fff"\n    content-color="#fff"\n    card-background="linear-gradient(120deg, #7f7fd5, #86a8e7, #91eae4)">\n    <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>\n    <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            attrs: {
              title: "Gradient Background Color",
              "title-color": "#fff",
              "content-color": "#fff",
              "card-background": "linear-gradient(to right, #56ab2f, #a8e063)",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("strong", [_vm._v("card-background")]),
              _vm._v(
                " prop to change background color of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<vx-card\n    title="Gradient Background Color"\n    title-color="#fff"\n    content-color="#fff"\n    card-background="linear-gradient(to right, #56ab2f, #a8e063)">\n    <p class="mb-3">You can use <strong>card-background</strong> prop to change background color of card. This prop supports hex, rgba, rgb and theme colors.</p>\n    <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n</vx-card>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            staticClass: "card-overlay bg-cover",
            attrs: {
              title: "Gradient Background Image",
              "title-color": "#fff",
              "content-color": "#fff",
              "card-background":
                "linear-gradient(120deg ,rgba(109,213,237,.8), rgba(33,147,176,0.5)), url(" +
                _vm.card_bg_img_1 +
                ")",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("strong", [_vm._v("card-background")]),
              _vm._v(
                " prop to change background of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n  <vx-card\n      title="Gradient Background Image"\n      title-color="#fff"\n      content-color="#fff"\n      :card-background="\'linear-gradient(120deg ,rgba(109,213,237,.8), rgba(33,147,176,0.5)), url(\' + card_bg_img_1 + \')\'"\n      code-toggler>\n      <p class="mb-3">You can use <strong>card-background</strong> prop to change background of card. This prop supports hex, rgba, rgb and theme colors.</p>\n      <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n  </vx-card>\n</template>\n\n<script>\nexport default{\n  data() {\n    return {\n      card_bg_img_1: require(\'@/assets/images/pages/card-bg-image-demo-1.jpg\')\n    }\n  }\n}\n</script>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base",
      },
      [
        _c(
          "vx-card",
          {
            staticClass: "card-overlay bg-cover",
            attrs: {
              title: "Gradient Background Image",
              "title-color": "#fff",
              "content-color": "#fff",
              "card-background":
                "linear-gradient(120deg ,rgba(247,97,161,0.5), rgba(140,27,171,.8)), url(" +
                _vm.card_bg_img_2 +
                ")",
              "code-toggler": "",
            },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can use "),
              _c("strong", [_vm._v("card-background")]),
              _vm._v(
                " prop to change background of card. This prop supports hex, rgba, rgb and theme colors."
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                "Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love."
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n  <vx-card\n      title="Gradient Background Image"\n      title-color="#fff"\n      content-color="#fff"\n      :card-background="\'linear-gradient(120deg ,rgba(247,97,161,0.5), rgba(140,27,171,.8)), url(\' + card_bg_img_2 + \')\'"\n      code-toggler>\n      <p class="mb-3">You can use <strong>card-background</strong> prop to change background of card. This prop supports hex, rgba, rgb and theme colors.</p>\n      <p class="mb-3">Oat cake powder sesame snaps. Chocolate bar dessert bonbon chocolate bar pudding apple pie muffin chocolate ice cream. I love bear claw I love.</p>\n  </vx-card>\n</template>\n\n<script>\nexport default{\n  data() {\n    return {\n      card_bg_img_2: require(\'@/assets/images/pages/card-bg-image-demo-2.jpg\')\n    }\n  }\n}\n</script>\n                '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }